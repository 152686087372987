export default function GGAvaxTokenIcon({ fill = '#0D0959' }: { fill?: string }) {
  return (
    <svg fill="none" height="24" viewBox="0 0 22 20" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill="white" height="11.912" width="11.912" x="4.63232" y="4.63184" />
      <path
        d="M13.1638 0.812516L19.3301 5.12186C20.8921 6.21439 21.5518 8.15906 20.9657 9.93898L18.6499 16.9668C18.0625 18.7467 16.3628 19.9581 14.4383 19.9662L6.84089 20C4.91635 20.0081 3.20578 18.8115 2.60333 17.037L0.22353 10.0295C-0.378913 8.25494 0.264427 6.30487 1.81551 5.19884L7.9422 0.835474C9.49465 -0.270559 11.6019 -0.278661 13.1638 0.812516Z"
        fill={fill}
      />
      <path
        d="M11.1951 13.5011L10.0001 13.4877C9.85717 13.4877 9.74076 13.6008 9.73929 13.7451L9.73192 14.3479C9.72603 14.8256 10.1047 15.2156 10.5777 15.2215C11.0507 15.2275 11.4383 14.845 11.4442 14.3672L11.4515 13.7645C11.4515 13.6201 11.3395 13.5025 11.1966 13.5011H11.1951Z"
        fill="white"
      />
      <path
        d="M10.426 5.29901C8.7594 5.38087 7.3846 6.75457 7.28587 8.43487C7.22546 9.4484 7.61889 10.3741 8.28197 11.026H8.28492L8.29524 11.0394L9.47995 12.0872C9.5831 12.178 9.71571 12.2286 9.85275 12.2286H11.3248C11.4633 12.2286 11.5945 12.178 11.6976 12.0872L12.6893 11.2091L12.847 11.0692L12.8543 11.0647C13.4968 10.4545 13.8976 9.59128 13.8976 8.63579C13.8961 6.73671 12.3209 5.20525 10.4245 5.29901H10.426ZM10.5895 7.07307C11.4412 7.07307 12.1367 7.77406 12.1367 8.63579C12.1367 9.49752 11.4427 10.197 10.5895 10.197C9.73634 10.197 9.04231 9.49603 9.04231 8.63579C9.04231 7.77555 9.73634 7.07307 10.5895 7.07307Z"
        fill="white"
      />
    </svg>
  )
}
